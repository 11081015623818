import Landing from "./Pages/Landing/Landing";
import Exam from "./Pages/Exam/Exam";
import Welcome from "./Pages/Welcome/welcome";
import { useState } from "react";

function App() {
  const [page, setPage] = useState("landing");

  const pageContent = {
    landing: <Landing />,
    exam: <Exam />,
    welcome: <Welcome setPage={setPage} />,
  };

  return (
    <div>
      <header className="sticky-header">
        <div className="header-container">
          <div className="logo" onClick={() => setPage("landing")}>
            <span>Kardashev</span> <img src="/images/logo192.png" alt="Logo" />
          </div>
          {page === "landing" && (
            <button
              className="get-started-btn"
              onClick={() => setPage("welcome")}
            >
              <span>GET STARTED</span>
            </button>
          )}
          {page === "welcome" && (
            <button className="get-started-btn" onClick={() => setPage("exam")}>
              <span>START EXAM</span>
            </button>
          )}
        </div>
      </header>

      {pageContent[page]}
    </div>
  );
}
export default App;
