import * as React from "react";
import algebra from "./algebra.json";
import AlertDialog from "../../Utilities/AlertDialogue";

function Buttons({
  setCurrentQuestion,
  setSelectedAnswer,
  currentQuestion,
  savedAnswers,
  setScore,
}) {
  const [open, setOpen] = React.useState(false);
  const exam = algebra.exam;

  const handlePrevButton = () => {
    if (!(currentQuestion === 0)) {
      setCurrentQuestion(currentQuestion - 1);
      setSelectedAnswer(null); // Reset selected answer when moving to the next question
    }
  };

  const handleSubmitButton = () => {
    let points = 0;
    Object.entries(savedAnswers).forEach(([question, answer]) => {
      if (exam[question].options[answer]) {
        points++;
      }
    });
    //   const score = Math.round((points / 50) * 100);
    const finalScore = Math.round((points / 39) * 100);
    // console.log(finalScore);
    setScore(finalScore);
  };

  const handleNextButton = () => {
    if (!(currentQuestion + 1 === exam.length)) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedAnswer(null); // Reset selected answer when moving to the next question
    }
  };

  return (
    <div className="buttons">
      <AlertDialog
        open={open}
        setOpen={setOpen}
        handleSubmitButton={handleSubmitButton}
      />

      <button onClick={() => handlePrevButton()}>PREV</button>
      <button
        onClick={() => {
          Object.keys(savedAnswers).length < 39
            ? setOpen(true)
            : handleSubmitButton();
        }}
      >
        SUBMIT
      </button>
      <button onClick={() => handleNextButton()}>NEXT</button>
    </div>
  );
}
export default Buttons;
