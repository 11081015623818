import "./welcome.css";

function Welcome({ setPage }) {
  return (
    <div className="app">
      <div className="welcome">
        <h1>
          While We're Building Our Hardware Engineering Currriculums, Please
          Enjoy This Algebra Exam
        </h1>
        <div className="engineers">
          <img src="/images/chemical.jpg" alt="Chemical Engineering"></img>{" "}
          <img src="/images/mechanical.jpg" alt="Mechanical Engineering"></img>{" "}
          <img src="/images/civil.png" alt="Civil Engineering"></img>{" "}
          <img src="/images/electrical.png" alt="Eletrical Engineering"></img>
        </div>
        <button onClick={() => setPage("exam")}>START EXAM</button>
      </div>
    </div>
  );
}
export default Welcome;
