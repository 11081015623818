import "./landing.css";
import "./../../header.css";

function Landing() {
  const aboutme = [
    "The bottleneck to technological innovation is us.",
    "All technologies come from human ingenuity.",
    "Anything one human understands, another human can understand.",
    "Humans are practical. We prioritize career over invention for its own sake.",
    "Employers filter applicants via college degrees.",
    "College degrees are a credential to signal intelligence, skill, & work ethic.",
    "College programs are bureaucratic, rigid, expensive, & time-consuming.",
    "With the internet, anyone can learn anything for free or cheap.",
    "You can learn whenever, wherever, & however you want.",
    "Colleges gatekeep their degrees. They require their archaic process.",
    "How can autodidacts credentialize their knowledge?",
    "Standardized testing, for nearly 2,000 years, has served this purpose.",
    "Kardashev is the standardized testing platform for autodidacts to earn their college degree.",
    "Instead of going to college to take an Algebra course, just take an Algebra exam. Now do the same with physics, biology, chemistry, and the rest of the curriculum.",
    "Once you pass all exams within the curriculum, you earn your college degree.",
    "Our institutions have gatekept us from leveling up.",
    "Everyone can be rich. They have gatekept us from being rich.",
    "It is time to take matters into our own hands.",
    "We will move up the Kardashev Scale.",
  ];

  return (
    <div className="App">
      <div className="dice">
        <div className="section1">
          <div className="section1-container">
            <div className="section1-headers">
              <h1>Hello World</h1>
              <h1>Kardashev is now live</h1>
              <h1>Let's push humanity forward</h1>
            </div>
            <img className="earth" alt="earth" src="/images/earth.png" />
          </div>
        </div>
      </div>

      <div className="section2">
        <div className="container2">
          <div className="videos">
            <iframe
              className="video vid1"
              src="https://www.youtube.com/embed/tnmmnpj_pX8?si=dYGIrVOpemNfssYV"
              title="Michio Kaku Explains Kardashev Scale"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
            <iframe
              className="video"
              src="https://www.youtube.com/embed/rhFK5_Nx9xY?si=yhAm7YUJm0e_6P48"
              title="Kurzgesagt Explains Kardashev Scale"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>
          <div className="words2">
            <span>KARDASHEV SCALE</span>
            <div className="body2">
              <h2>Type 1: Planetary Civilization</h2>
              <h2>Type 2: Stellar Civilization</h2>
              <h2>Type 3: Galactic Civilization</h2>
              <h2>Type 4: Universal Civilization</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="dice">
        <div className="section3">
          <h1>Kardashev's Philosophy & Mission in a Nutshell</h1>
          <div className="shorts">
            <iframe
              className="short"
              src="https://www.youtube.com/embed/sltQuGnG0A8"
              title="Marc Andreessen Explains Human Progress"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <iframe
              className="short"
              src="https://www.youtube.com/embed/r25DcOBTUgM"
              title="Naval Ravikant: Everyone Can Be Rich"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <iframe
              className="short"
              src="https://www.youtube.com/embed/zaBCZ6CD63I"
              title="Marc Andreessen Explains The Power of The Internet"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <h1>Technology is Upstream of All Human Prosperity</h1>
        </div>
      </div>

      <div className="section4">
        <div className="aboutme">
          {" "}
          {aboutme.map((line, index) => (
            <h2 key={index}>{line}</h2>
          ))}
        </div>

        <div className="images">
          <img src="/images/chemical.jpg" alt="Chemical Engineering"></img>{" "}
          <img src="/images/mechanical.jpg" alt="Mechanical Engineering"></img>{" "}
          <img src="/images/civil.png" alt="Civil Engineering"></img>{" "}
          <img src="/images/electrical.png" alt="Eletrical Engineering"></img>
        </div>
      </div>
    </div>
  );
}
export default Landing;
