import "./exam.css";
import algebra from "./algebra.json";
import { useEffect, useState } from "react";
import Buttons from "./Buttons";
import Score from "../Score/score";

function Exam() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null); // State to track the selected answer
  const [savedAnswers, setSavedAnswers] = useState({});
  const [score, setScore] = useState(-1);

  const exam = algebra.exam;
  const currentExamQuestion = exam[currentQuestion];

  useEffect(() => {
    if (selectedAnswer !== null) {
      const tempObject = { ...savedAnswers };
      tempObject[currentQuestion] = selectedAnswer;
      setSavedAnswers(tempObject);
    }
  }, [selectedAnswer, currentQuestion, savedAnswers]);

  return score > -1 ? (
    <Score score={score} />
  ) : (
    <div className="app">
      <div>
        <div className="question-container">
          {/* QUESTION */}
          <h1>{`${currentQuestion + 1}.) ${
            exam[currentQuestion].question
          }`}</h1>{" "}
          {/* ANSWERS */}
          <div className="answers-container">
            <form className="answers">
              {Object.keys(currentExamQuestion.options).map((answer, index) => (
                <div key={index}>
                  <input
                    type="radio"
                    id={`choice-${index}`}
                    name="choice"
                    value={answer}
                    onChange={(e) => setSelectedAnswer(e.target.value)} // Set the handler for change events
                    checked={
                      selectedAnswer === answer
                        ? selectedAnswer === answer
                        : answer === savedAnswers[currentQuestion]
                    } // Ensure correct radio button is selected
                  />
                  <label htmlFor={`choice-${index}`}>{answer}</label>
                </div>
              ))}
            </form>
          </div>
          {/* BUTTONS */}
          <Buttons
            setCurrentQuestion={setCurrentQuestion}
            setSelectedAnswer={setSelectedAnswer}
            currentQuestion={currentQuestion}
            savedAnswers={savedAnswers}
            setScore={setScore}
          />
        </div>
      </div>
    </div>
  );
}

export default Exam;
