import "./../Score/score.css";

function Score({ score }) {
  const h2 = score >= 80;

  return (
    <div className="app">
      <div className="score">
        <h1>Your Score: {score}%</h1>
        {h2 ? (
          <h1 style={{ color: "darkgreen" }}>
            Congratulations! You passed this exam!
          </h1>
        ) : (
          <h1 style={{ color: "darkred" }}>
            You failed. Better luck next time.
          </h1>
        )}
      </div>
    </div>
  );
}
export default Score;
